/* eslint-disable no-console */
import {generatePath, matchPath} from 'react-router-dom';
import {ROOT_PATH} from 'utils/constants/app.js'

export const ROUTE_PARAMS = {
  CONTRACT: ':contract',
  TEST: ':test',
  AUDIT: ':audit',
  NEWS: ':news',
  USER: ':user'
}

const ROOT_PATH_WITH_CONTRACT = `${ROOT_PATH}contract/:contract/`;
const ROOT_PATH_WITH_TEST = `${ROOT_PATH_WITH_CONTRACT}tests/:test/`;
const ROOT_PATH_WITH_AUDIT = `${ROOT_PATH_WITH_CONTRACT}audits/:audit/`;
const ROOT_PATH_WITH_ACCOUNT = `${ROOT_PATH_WITH_CONTRACT}account/`;
const ROOT_PATH_ACCOUNT = `${ROOT_PATH}account/`;

export const ROUTES = {
  DOWNLOAD: `${ROOT_PATH}download/`,
  ERROR: `${ROOT_PATH}error`,
  CHOICE: `${ROOT_PATH}contract-choice`,
  BASE: `${ROOT_PATH_WITH_CONTRACT}`,
  HOME: `${ROOT_PATH_WITH_CONTRACT}home`,
  TESTS: `${ROOT_PATH_WITH_CONTRACT}tests`,
  TEST: {
    BASE: `${ROOT_PATH_WITH_TEST}`,
    GENERAL: `${ROOT_PATH_WITH_TEST}testInfo`,
    FILES: `${ROOT_PATH_WITH_TEST}testFiles`,
    LAB: `${ROOT_PATH_WITH_TEST}testLabs`,
    BILLS: `${ROOT_PATH_WITH_TEST}testBills`
  },
  BILLS: `${ROOT_PATH_WITH_CONTRACT}bills`,
  DIPLOMAS: `${ROOT_PATH_WITH_CONTRACT}diplomas`,
  CONTRACT: {
    BASE: `${ROOT_PATH_WITH_CONTRACT}contract`,
    GENERAL: `${ROOT_PATH_WITH_CONTRACT}contract/info`,
    CONTACTS: `${ROOT_PATH_WITH_CONTRACT}contract/contacts`,
    FILES: `${ROOT_PATH_WITH_CONTRACT}contract/files`,
    PROGRAM_FILES: `${ROOT_PATH_WITH_CONTRACT}contract/programFiles`
  },
  AUDITS: `${ROOT_PATH_WITH_CONTRACT}audits`,
  AUDIT: {
    BASE: `${ROOT_PATH_WITH_AUDIT}`,
    GENERAL: `${ROOT_PATH_WITH_AUDIT}auditInfo`,
    FILES: `${ROOT_PATH_WITH_AUDIT}auditFiles`,
    LAB: `${ROOT_PATH_WITH_AUDIT}auditLabs`,
    BILLS: `${ROOT_PATH_WITH_AUDIT}auditBills`
  },
  ADMINISTRATION: {
    BASE: `${ROOT_PATH_WITH_CONTRACT}administration`,
    GENERAL: `${ROOT_PATH_WITH_CONTRACT}administration/general`,
    NEWS: {
      BASE:`${ROOT_PATH_WITH_CONTRACT}administration/news`,
      CREATE: `${ROOT_PATH_WITH_CONTRACT}administration/news/create`,
      EDIT: `${ROOT_PATH_WITH_CONTRACT}administration/news/:id`
    },
    USERS: {
      BASE: `${ROOT_PATH_WITH_CONTRACT}administration/users`,
      CREATE: `${ROOT_PATH_WITH_CONTRACT}administration/users/createUser`,
      EDIT: `${ROOT_PATH_WITH_CONTRACT}administration/users/:id`
    }
  },
  ACCOUNT: {
    BASE: `${ROOT_PATH_WITH_ACCOUNT}`,
    PROFILE: `${ROOT_PATH_WITH_ACCOUNT}profile`,
    COOKIES: `${ROOT_PATH_WITH_ACCOUNT}cookies`
  },
  GENERAL_ACCOUNT: {
    BASE: `${ROOT_PATH_ACCOUNT}`,
    PROFILE: `${ROOT_PATH_ACCOUNT}profile`,
    COOKIES: `${ROOT_PATH_ACCOUNT}cookies`
  }
};

export const GENERATE_ROUTE = (route, dict) => {
  const placeholderRegexp = /:([^/:]+)\/?/g
  const placeholders = [...route.matchAll(placeholderRegexp)];

  placeholders.forEach((match) => {
    const param = match[1];
    if (!dict[param]) {
      console.warn(`No ${param} supplied, link will not work ${route}`);
      return '';
    }
  })

  return generatePath(route, dict);
}

export const ROUTE_MATCHES = (location, route) => matchPath(location.pathname, {
  path: route,
  exact: false
});

export const GENERATE_TAB_VALUE = (location, route) => (ROUTE_MATCHES(location, route) ? 0 : NaN);

export const findMatchingRoute = (pathname, route) => {
  if (!route) {
    return null;
  }

  if (Array.isArray(route)) {
    throw new Error('not supported');
  }

  if (typeof route === 'object') {
    let subRoutes = Object.values(route);
    let activeSubRoutes = subRoutes
      .map(sub => findMatchingRoute(pathname, sub))
      .filter(e => e);

    const splitPath = pathname.split('/');
    const lastElementPath = splitPath[splitPath.length -1];

    let routeResult = null;
    activeSubRoutes.forEach(route => {
      const splitRoute = route.split('/');
      const lastElementRoute = splitRoute[splitRoute.length -1];

      if (lastElementPath === lastElementRoute) {
        routeResult = route;
      }
    });

    if (routeResult === null) {
      const idActiveSubRoute = activeSubRoutes.filter(str => str.includes('id'))[0];
      if (idActiveSubRoute !== null
        && idActiveSubRoute !== undefined) {
        routeResult = idActiveSubRoute;
      }
    }

    return routeResult;
  }

  let match = matchPath(pathname, {
    path: route,
    exact: false
  });
  return match ? route : null;
}