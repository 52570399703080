/* eslint-disable max-len */
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { CustomButton } from 'commons/Button/CustomButton';
import { DialogType } from 'commons/Dialog/DialogTypes';
import CustomIcon from 'commons/Icon/CustomIcon';
import FileDownloader from 'commons/Icon/FileDownloader';
import LocalizedText, { localize } from 'commons/LocalizedText/LocalizedText';
import { INITIAL_PAGE, INITIAL_ROWS_PER_PAGE, SORTING } from 'commons/Table/CustomTable';
import { useTablePaginationParams, useTableSortParams } from 'commons/Table/CustomTableHooks';
import { CustomTableWithInfiniteScrolling } from 'commons/Table/CustomTableWithInfiniteScrolling';
import { CustomTableWithQuerySort } from 'commons/Table/CustomTableWithQuerySort';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { FileUploadDialog } from 'components/FilesTab/FileUploadDialog';
import { useDialog } from 'hook/DialogProvider';
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import useRealParams from 'hook/UseRealParams';
import React, { useCallback, useEffect, useState } from 'react';
import { ProductTestService } from 'services/ProductTestService';
import { TestStatuses } from 'utils/constants/test.status';
import { generateRow } from 'utils/helpers/RowHelper';
import { timestampToDate } from 'utils/processing/dates';

const useStyles = makeStyles((theme) => ({
  generalContainer: {
    display: '-webkit-inline-box',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  generalText: {
    fontWeight: 'bold'
  },
  inlineCompletion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  gridPadding: {
    padding: theme.spacing(1)
  },
  sectionTitle: {
    display: 'block',
    width: '100%',
    borderBottom: '1px solid',
    color: 'grey',
    borderColor: 'grey',
    marginBottom: '1rem',
    fontSize: '1.5rem'
  },
  tableContainer: {
    maxHeight: '20vw',
    width: '100%',
    overflowX: 'auto',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '12px',
      width: '12px'
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '90px',
      backgroundColor: theme.palette.primary.light
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.main
    }
  },
  editIcon: {
    color: theme.palette.primary.main
  },
  deleteIcon: {
    color: theme.palette.error.dark
  }
}
));

const defaultPagination = {
  page: INITIAL_PAGE,
  rowsPerPage: INITIAL_ROWS_PER_PAGE
};
const defaultSorting = {
  orderBy: 'uploadDate',
  order: SORTING.DESC
};

export const FilesTab = ({ files, status }) => {
  const classes = useStyles();
  const { test: testId } = useRealParams();
  const showDialog = useDialog();
  const showSnackbar = useLocalizedSnackBar();
  const [ documents, setDocuments ] = useState([]);
  const [ total, setTotal ] = useState();
  const [ isLoadingDocumentTable, setIsLoadingDocumentTable ] = useState(false);
  const [ uploadableFileTypes, setUploadableFileTypes ] = useState([]);
  const [ editionFileType, setEditionFileType ] = useState(null);
  const [ editedFileId, setEditedFileId ] = useState(null);
  const [ editedFileName, setEditedFileName ] = useState(null);

  const sortParams = useTableSortParams(defaultSorting.orderBy, defaultSorting.order);
  const paginationParams = useTablePaginationParams();

  const [ isUploadDialogOpen, setUploadDialogOpen ] = useState(false);
  const [ isEditFileDialogOpen, setEditFileDialogOpen ] = useState(false);


  const laboratoryReportsHeaders = [
    generateRow({
      name: 'laboratoryName',
      width: '50%',
      template: (row) => row?.laboratoryName
    }),
    generateRow({
      name: 'labReport',
      isSortable: false,
      width: '25%',
      template: (row) => (
        (row.status === TestStatuses.RESULTS_SENT || row.status === TestStatuses.COMPLETED)
          ? <CustomTooltip title="downloadLabReportToolip">
            <span>
              <FileDownloader fileId={row.id} />
            </span>
          </CustomTooltip>
          : '-'
      )
    }),
    generateRow({
      name: 'date',
      label: 'receivedOn',
      width: '25%',
      template: (row) => (
        <Typography variant="body2">{timestampToDate(row.date)}</Typography>
      )
    })
  ];

  const technicalFilesHeaders = [
    generateRow({
      name: 'document',
      width: '50%',
      template: (row) => (
        <Grid className={classes.inlineCompletion} container spacing={2} wrap='nowrap'>
          <Grid item >
            <CustomTooltip title="downloadDocument">
              <span>
                <FileDownloader fileId={row.idCrypted} />
              </span>
            </CustomTooltip>
          </Grid>
          <Grid item >
            {row.name ?? '-'}
          </Grid>
        </Grid>
      )
    }),
    generateRow({
      name: 'fileType',
      width: '25%',
      template: (row) => (
        <LocalizedText>
          {row.type?.id}
        </LocalizedText>
      )
    }),
    generateRow({
      name: 'uploadDate',
      width: '15%',
      template: (row) => (
        <Typography variant="body2">{timestampToDate(row.creationDate)}</Typography>
      )
    }),
    generateRow({
      name: 'actions',
      isSortable: false,
      width: '10%',
      template: (row) => (
        <>
          <Grid container>
            <Grid item>
              {row.canEdit && (
                <CustomTooltip className={'placeholder'} title="edit">
                  <IconButton onClick={() => {
                    setEditionFileType(row.type.id);
                    setEditedFileId(row.idCrypted);
                    setEditedFileName(row.name);
                    setEditFileDialogOpen(true);
                  }} size={'small'}>
                    <CustomIcon className={classes.editIcon} icon={faPen}/>
                  </IconButton>
                </CustomTooltip>
              )}
            </Grid>
            <Grid item>
              {row.canDelete && (<CustomTooltip className={'placeholder'} title="delete">
                <IconButton onClick={() => handleFileDelete(row.type.id, row.idCrypted)} size={'small'}>
                  <CustomIcon className={classes.deleteIcon} icon={faTrash}/>
                </IconButton>
              </CustomTooltip>)}
            </Grid>
          </Grid>
        </>
      )
    })
  ];

  const fetchDocumentPage = useCallback(({order, orderBy, page, rowsPerPage}, clearContent=false) => {
    setIsLoadingDocumentTable(true);
    ProductTestService.getTestDocuments(testId, {order, orderBy}, {page, rowsPerPage})
      .then((response) => {
        if (clearContent) {
          setDocuments(response?.results);
        } else {
          setDocuments(prevDocuments => [...prevDocuments, ...response?.results]);
        }
        setTotal(+response?.total);
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
      })
      .finally(() => {
        setIsLoadingDocumentTable(false);
      });
  }, [
    // reactive values that should not change
    testId, // from path
    // non reactive values
    setIsLoadingDocumentTable, setDocuments, setTotal,
    showDialog
  ])

  useEffect(() => {
    fetchDocumentPage({...defaultSorting, ...defaultPagination}, true);
  }, [
    // reactive values that should not change
    fetchDocumentPage // from useCallback
  ]);

  useEffect(() => {
    ProductTestService.getUploadableFileTypes(testId)
      .then((response) => {
        setUploadableFileTypes(response);
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
      });
  }, [
    // reactive values that should not change
    testId, // from path
    // non reactive components
    setUploadableFileTypes,
    showDialog
  ]);

  const { labReports } = files;

  const labReportsWithStatus = labReports ? labReports.map((labReport) => ({ status, ...labReport })) : [];

  const refreshTestFiles = () => {
    ProductTestService.getUploadableFileTypes(testId)
      .then((response) => {
        setUploadableFileTypes(response);
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
      });
    paginationParams?.setPage(defaultPagination.page);
    fetchDocumentPage({...sortParams, ...paginationParams, page: defaultPagination.page}, true);
  }

  const handleFileDelete = (fileType, fileId) => {
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: localize('deleteTestFileConfirmation'),
      onSubmit: () => {
        handleFileEdit(null, fileType, fileId);
      }
    });
  }

  const handleFileEdit = (newFile, fileType, fileId) => {
    setIsLoadingDocumentTable(true);
    ProductTestService.removeProductTestDocument(testId, fileId, newFile === null)
      .then(() => {
        if (newFile) {
          const formData = new FormData();
          formData.append("file", newFile);
          formData.append("fileType", fileType);
          ProductTestService.uploadProductTestDocument(testId, formData)
            .then(() => {
              showSnackbar('documentEdited', {variant: 'success'});
              refreshTestFiles();
            }).catch((errorMessage) => {
              showDialog({
                variant: DialogType.ERROR,
                title: 'error',
                description: errorMessage
              });
            });
        } else {
          showSnackbar('documentDeleted', {variant: 'success'});
          refreshTestFiles();
        }
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
        refreshTestFiles();
      });
  };

  function onTestDocumentUploaded() {
    showSnackbar('documentAdded', {variant: 'success'});
    ProductTestService.getUploadableFileTypes(testId)
      .then((response) => {
        setUploadableFileTypes(response);
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
      });
    paginationParams?.setPage(defaultPagination.page);
    fetchDocumentPage({...sortParams, ...paginationParams, page: defaultPagination.page}, true);
  }

  const uploadTestDocument = (file, fileType) => {
    setIsLoadingDocumentTable(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType ?? editionFileType);
    ProductTestService.uploadProductTestDocument(testId, formData)
      .then(() => {
        onTestDocumentUploaded();
      }).catch((errorMessage) => {
        showDialog({
          variant: DialogType.ERROR,
          title: 'error',
          description: errorMessage
        });
      })
      .finally(() => setIsLoadingDocumentTable(false));
  }

  return (<>
    <FileUploadDialog
      fileName="productTestDocument"
      onClose={() => setUploadDialogOpen(false)}
      onSubmit={uploadTestDocument}
      open={isUploadDialogOpen}
      title="uploadNewTechnicalFile"
      uploadableFileTypes={uploadableFileTypes}
    />
    <FileUploadDialog
      editedFileId={editedFileId}
      editionFileType={editionFileType}
      fileName="productTestDocument"
      initialFileName={editedFileName}
      onClose={() => setEditFileDialogOpen(false)}
      onSubmit={handleFileEdit}
      open={isEditFileDialogOpen}
      title="editTechnicalFile"
      uploadableFileTypes={uploadableFileTypes}
    />
    <Grid container justifyContent="flex-end" xs={12}>
      <CustomButton
        className={classes.margin}
        disabled={!uploadableFileTypes || uploadableFileTypes.length === 0}
        onClick={() => setUploadDialogOpen(true)}
      >
        {localize('addFileButton')}
      </CustomButton>
    </Grid>
    <Grid container spacing={1}>
      <Grid className={classes.generalContainer} item>
        <LocalizedText className={classes.sectionTitle}>
          technicalFileSection
        </LocalizedText>
        <div className={classes.tableContainer}>
          <CustomTableWithInfiniteScrolling
            headers={technicalFilesHeaders}
            isLoading={isLoadingDocumentTable}
            onMaxScroll={(nextPage) => {
              paginationParams?.setPage(nextPage);
              fetchDocumentPage({...sortParams, ...paginationParams, page: nextPage});
            }}
            onSort={(newSortParams) => {
              paginationParams.setPage(defaultPagination.page);
              paginationParams.setRowsPerPage(defaultPagination.rowsPerPage);
              fetchDocumentPage({...newSortParams, ...defaultPagination}, true);
            }}
            paginationParams={paginationParams}
            rowKey="filesTabDocument"
            rows={documents ?? []}
            sortParams={sortParams}
            total={total}
          />
        </div>
      </Grid>
    </Grid>
    <Grid className={classes.generalContainer} item>
      <LocalizedText className={classes.sectionTitle}>
        laboratoryReportSection
      </LocalizedText>
    </Grid>
    <CustomTableWithQuerySort
      headers={laboratoryReportsHeaders}
      rowKey="downloadLink"
      rows={labReportsWithStatus}
    />
  </>
  );
};