/* eslint-disable no-param-reassign */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { CustomTooltip } from 'commons/Tooltip/CustomTooltip';
import { ConditionalWrapper } from 'hook/ConditionalWrapper';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  base: {
    paddingLeft: 13,
    paddingRight: 13,
    borderRadius: 19
  },
  primary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover
    },
    '&:active': {
      backgroundColor: theme.palette.primary.active
    }
  },
  primaryLight: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      color: theme.palette.common.white
    },
    '&:active': {
      backgroundColor: theme.palette.primary.active
    }
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  header: {
    color: theme.palette.common.white,
    border: '1px solid white',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover
    },
    '&:active': {
      backgroundColor: theme.palette.primary.active
    }
  },
  baseButton: {
    "&:disabled": {
      cursor: 'not-allowed',
      color: theme.palette.text.disabledDark,
      backgroundColor: theme.palette.background.disabled,
      '&:hover': {
        backgroundColor: theme.palette.background.disabled
      },
      '&:active': {
        pointerEvents: 'none'
      }
    }
  }
}));

export const CustomButton = ({
  children, className, variant, disabled, tooltip, ...props
}) => {
  const classes = useStyles();
  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={(childrenNodes) => (
        <CustomTooltip title={tooltip}>
          {childrenNodes}
        </CustomTooltip>
      )}
    >
      <Button className={`${classes.base} ${classes.baseButton} ${classes[variant]} ${className}`} disabled={disabled} {...props}>
        <LocalizedText>
          {children}
        </LocalizedText>
      </Button>
    </ConditionalWrapper>
  );
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.string
};

CustomButton.defaultProps = {
  className: '',
  tooltip: null,
  variant: 'primary'
};