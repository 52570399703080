import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {useCustomerInfo} from 'hook/CustomerInfoProvider';
import React, {useEffect, useState} from 'react';
import {MOBILE_BREAKPOINT} from 'utils/constants/responsive';
import {isSuperAdmin} from 'utils/constants/roles';
import {generateRow} from 'utils/helpers/RowHelper';
import LocalizedText, {localize} from 'commons/LocalizedText/LocalizedText';
import {CustomTooltip} from 'commons/Tooltip/CustomTooltip';
import Typography from '@material-ui/core/Typography';
import {timestampToDate} from 'utils/processing/dates';
import {CustomTableWithQuerySort} from 'commons/Table/CustomTableWithQuerySort';
import CustomIcon from 'commons/Icon/CustomIcon';
import {faEdit} from '@fortawesome/pro-light-svg-icons';
import {NewsService} from 'services/NewsService';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import {FlagIcon} from './FlagIcon';
import {ROUTES} from 'utils/constants/routes';
import {generatePath, useHistory, useParams} from 'react-router-dom';
import {faAdd, faTrash} from '@fortawesome/pro-solid-svg-icons';
import {NewsState} from 'components/News/news-admin-projection';
import {DialogType} from 'commons/Dialog/CustomDialog';
import {useDialog} from 'hook/DialogProvider';
import useLocalizedSnackBar from 'hook/UseLocalizedSnackBar';
import {FloatingButtonMenu} from 'commons/Button/FloatingButtonMenu';
import {FloatingButton} from 'commons/Button/FloatingButton';


const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  divider: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  },
  gridContainer: {
    backgroundColor: theme.palette.primary.tableHover,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  newsContainerSummary: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    '&::before': {
      content: '"-"',
      display: 'block',
      backgroundColor: theme.palette.secondary.main,
      marginRight: '5px',
      paddingTop: '5px',
      paddingBottom: '5px'
    }
  },
  newsContainerDetail: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  icon: {
    color: theme.palette.primary.main
  }
}));

const EditNewsButton = ({news}) => {
  const styles = useStyles();
  const {contract} = useParams();
  const history = useHistory();

  const editNews = (newsId) => {

    return history.push(generatePath(ROUTES.ADMINISTRATION.NEWS.EDIT,
      {
        "contract": contract,
        "id": newsId
      }
    ));
  }

  return (
    <>
      {(news.state !== NewsState.OUTDATED) && (
        <CustomTooltip title="edit">
          <IconButton
            onClick={() => editNews(news.recid)}
            size="small"
          >
            <CustomIcon className={styles.icon} icon={faEdit}/>
          </IconButton>
        </CustomTooltip>
      )}
    </>
  )

}

const DeleteNewsButton = ({news, newsList, setNewsList}) => {
  const styles = useStyles();
  const showDialog = useDialog();
  const showSnackbar = useLocalizedSnackBar();

  const deleteNews = (newsId) => {
    showDialog({
      variant: DialogType.CONFIRMATION,
      title: 'confirmAction',
      description: localize('newsDeleteConfirmAction', {
        number: news.number
      }),
      onSubmit: () => {
        NewsService.deleteNews(newsId)
          .then(() => setNewsList(newsList.filter(n => n.recid !== news.recid)))
          .then(() => showSnackbar('deleteNews', {variant: 'success'}))
          .catch((error) => showDialog({
            variant: DialogType.ERROR,
            title: 'error',
            description: error
          }))
      }
    });
  };

  return (
    <>
      {(news.state !== NewsState.ONGOING) && (
        <CustomTooltip title="delete">
          <IconButton
            onClick={() => deleteNews(news.recid)}
            size="small"
          >
            <CustomIcon className={styles.icon} icon={faTrash}/>
          </IconButton>
        </CustomTooltip>
      )}
    </>
  )
}

const getHeaders = (newsList, setNewsList) => [
  generateRow({
    name: 'number',
    label: 'newsNumber',
    template: (row) => (
      <Typography>
        {row.number}
      </Typography>
    ),
    width: '130px'
  }),
  generateRow({
    name: 'startDate',
    template: (row) => (
      <Typography variant="body2">{timestampToDate(row.startDate)}</Typography>
    ),
    width: '155px'
  }),
  generateRow({
    name: 'endDate',
    template: (row) => (
      <Typography variant="body2">{timestampToDate(row.endDate)}</Typography>
    ),
    width: '140px'
  }),
  generateRow({
    name: 'title',
    isSortable: false,
    template: (row) => (
      <LocalizedText>
        {' '}
        {(row.translations.filter(t => t.languageCode === 'en')[0] || row.translations[0]).title}
        {' '}
      </LocalizedText>
    ),
    width: 'auto'
  }),
  generateRow({
    name: 'language',
    isSortable: false,
    template: (row) => (
      <>
        <FlagIcon
          country="gb"
          disabled={row.translations.filter(t => t.languageCode === 'en').length === 0}
          scale={0}
          style={{marginRight: "10px"}}
        />
        <FlagIcon
          country={"fr"}
          disabled={row.translations.filter(t => t.languageCode === 'fr').length === 0}
          scale={0}
          style={{marginRight: "10px"}}
        />
      </>
    ),
    width: '135px'
  }),
  generateRow({
    name: 'actions',
    isSortable: false,
    template: (row) => (
      <>
        <EditNewsButton news={row}/>
        <DeleteNewsButton news={row} newsList={newsList} setNewsList={setNewsList}/>
      </>
    ),
    width: '100px'
  })
];

const NewsGrid = ({newsList, setNewsList}) => {

  return (
    <>
      <Grid container>
        <CustomTableWithQuerySort
          headers={getHeaders(newsList, setNewsList)}
          rowKey=""
          rows={newsList ?? []}
        />
      </Grid>
    </>
  );
}

const ExpandableNewsGrid = ({title, newsList, setNewsList, styles, defaultExpanded=true}) => {
  return (
    <>
      <Accordion
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          className={styles.gridContainer}
          expandIcon={<ExpandMoreIcon/>}
          id="panel1a-header"
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NewsGrid newsList={newsList} setNewsList={setNewsList}/>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

NewsGrid.propTypes = {};
export const AdministrationNewsPage = (props) => {
  const styles = useStyles();
  const {role} = useCustomerInfo();
  const {contract} = useParams();
  const history = useHistory();
  const [upcommingNews, setUpcommingNews] = useState([]);
  const [outdatedNews, setOutdatedNews] = useState([]);
  const [ongoingNews, setOngoingNews] = useState([]);

  useEffect(() => {
    NewsService.getOngoingNews().then(resp => setOngoingNews(resp));
    NewsService.getOutdatedNews().then(resp => setOutdatedNews(resp));
    NewsService.getUpcommingNews().then(resp => setUpcommingNews(resp));
  }, [])

  const createNews = () => {
    return history.push(generatePath(ROUTES.ADMINISTRATION.NEWS.CREATE,
      {
        "contract": contract
      }
    ));
  }

  return (
    <>
      {
        isSuperAdmin(role)
        && (
          <>
            <FloatingButtonMenu>
              <FloatingButton
                icon={faAdd}
                isMainButton
                onClick={() => createNews()}
                tooltip={
                  <LocalizedText>
                    addNews
                  </LocalizedText>
                }
              />
            </FloatingButtonMenu>

            <Accordion
              defaultExpanded={true}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                className={styles.newsContainerSummary}
                expandIcon={<ExpandMoreIcon/>}
                id="panel1a-header"
              >
                <LocalizedText>
                  allNews
                </LocalizedText>
              </AccordionSummary>
              <AccordionDetails
                className={styles.newsContainerDetail}
              >
                <ExpandableNewsGrid
                  newsList={upcommingNews}
                  setNewsList={setUpcommingNews}
                  styles={styles}
                  title={<LocalizedText>upcomingNews</LocalizedText>}/>
                <ExpandableNewsGrid
                  newsList={ongoingNews}
                  setNewsList={setOngoingNews}
                  styles={styles}
                  title={<LocalizedText>ongoingNews</LocalizedText>}/>
                <ExpandableNewsGrid
                  defaultExpanded={false}
                  newsList={outdatedNews}
                  setNewsList={setOutdatedNews}
                  styles={styles}
                  title={<LocalizedText>outdatedNews</LocalizedText>}/>
              </AccordionDetails>
            </Accordion>

          </>
        )
      }
    </>
  );
};