import React from 'react';
import { localize } from 'commons/LocalizedText/LocalizedText';
import { generateRow, limitResults } from 'utils/helpers/RowHelper';
import { Tooltip } from '@material-ui/core';

export const mailOrLoginRow = (width, mobileWidth) => generateRow({
  name: 'mailOrLogin',
  label: 'usersMailOrLogin',
  width: width,
  mobileWidth: mobileWidth,
  isSortable: true,
  isOnMobile: mobileWidth,
  template: (row) => (
    <div>
      {row?.ssoEmail ?? ''}
    </div>
  )
});

export const lastnameRow = (width, mobileWidth) => generateRow({
  name: 'lastName',
  label: 'usersLastName',
  width: width,
  isSortable: true,
  isOnMobile: false,
  template: (row) => (
    <div>
      {row?.lastName ?? ''}
    </div>
  )
});

export const firstnameRow = (width, mobileWidth) => generateRow({
  name: 'firstName',
  label: 'usersFirstName',
  width: width,
  isSortable: true,
  isOnMobile: false,
  template: (row) => (
    <div>
      {row?.firstName ?? ''}
    </div>
  )
});

export const fullnameRow = (width, mobileWidth) => generateRow({
  name: 'fullName',
  label: 'usersFullName',
  mobileWidth: mobileWidth,
  isOnDesktop: false,
  isSortable: true,
  template: (row) => (
    <div>
      {row?.lastName ?? ''}
      {' '}
      {row?.firstName ?? ''}
    </div>
  )
});

export const statusRow = (width, mobileWidth) => generateRow({
  name: 'status',
  label: 'usersStatus',
  width: width,
  mobileWidth: mobileWidth,
  isSortable: true,
  template: (row) => (
    <div>
      {localize(row?.status) ?? ''}
    </div>
  )
})

export const profileRow = (width, mobileWidth) => generateRow({
  name: 'profile',
  label: 'usersProfile',
  width: width,
  mobileWidth: mobileWidth,
  isSortable: true,
  template: (row) => (
    <div>
      {localize(row?.role) ?? ''}
    </div>
  )
});

export const contractRow = (width, mobileWidth) => generateRow({
  name: 'contract',
  label: 'usersContracts',
  width: width,
  isSortable: false,
  isOnMobile: false,
  template: (row) => {
    const contracts = row?.contractNumbers || [];
    const threshold = 4;
    const divider = '\n';

    return (
      <div>
        <Tooltip title={contracts.join(divider)}>
          <p>{limitResults(contracts, threshold, divider)}</p>
        </Tooltip>
      </div>
    )
  }
})

export const companyRow = (width, mobileWidth) => generateRow({
  name: 'company',
  label: 'usersCompanies',
  width: width,
  mobileWidth: mobileWidth,
  isSortable: false,
  template: (row) => {
    const companiesNames = row?.companiesNames || [];
    const threshold = 4;
    const divider = '\n';
    return (
      <div>
        <Tooltip title={companiesNames.join(divider)}>
          <p>{limitResults(companiesNames ?? '', threshold, divider)}</p>
        </Tooltip>
      </div>
    )
  }
})

export const layoutEccAdminView = [
  mailOrLoginRow('15%', null),
  lastnameRow('15%', null),
  firstnameRow('15%', null),
  fullnameRow(null, '25%'),
  contractRow('15%', null),
  companyRow('20%', '25%'),
  statusRow('10%', '25%'),
  profileRow('10%', '25%')
];

export const layoutCustomerAdminView = [
  mailOrLoginRow('25%', null),
  lastnameRow('20%', null),
  firstnameRow('20%', null),
  fullnameRow(null, '34%'),
  statusRow('25%', '33%'),
  profileRow('10%', '33%')
];