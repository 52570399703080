import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Btn from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from 'commons/Button/CustomButton';
import CustomIcon from 'commons/Icon/CustomIcon';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { DialogType } from './DialogTypes';

const useStyles = makeStyles((theme) => ({
  MuiDialog: {
    padding: `${0}px`
  },
  MuiDialogTitle: {
    padding: `${0}px`
  },
  DialogActions: {
    margin: `${0}px`,
    padding: `${10}px`
  },
  DialogContent: {
    margin: `${0}px`,
    padding: `${0}px ${0}px`
  },
  Title: {
    margin: `${0}px`,
    padding: `${15}px`,
    paddingTop: `${2}px`,
    paddingBottom: `${2}px`,
    fontSize: `${24}px`,
    textAlign: 'left',
    color: '#ffffff',
    backgroundImage: `linear-gradient(80deg,${theme.palette.primary.main},${theme.palette.secondary.main})`
  },
  actionButton: {
    minWidth: '80px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default
  },
  actionButtonDisabledOverride: {
    "&:disabled": {
      minWidth: '80px',
      backgroundColor: theme.palette.primary.light
    }
  },
  actionButtonSecondary: {
    minWidth: '80px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  submitButton: {
    backgroundColor: theme.palette.error.main
  },
  errorButton: {
    backgroundColor: theme.palette.success.main
  }
}));

export const DialogWrapper = ({
  children,
  open,
  preventSubmit,
  title,
  variant,
  onSubmit,
  onClose,
  hideActions
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.MuiDialog,
        error: variant === DialogType.ERROR ? classes.error : ''
      }}
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogTitle
        classes={{root: classes.MuiDialog}}
      >
        <div className={`${classes.Title} ${(variant === DialogType.ERROR) && classes.error}`}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
          >
            <Grid item>
              {title}
            </Grid>
            <Grid item>
              <Btn onClick={onClose} >
                <FontAwesomeIcon color="white" icon={faTimes} size="2x"/>
              </Btn>
            </Grid>
          </Grid>
        </div>
      </DialogTitle>

      <DialogContent classes={{root: classes.DialogContent}}>
        {children}
      </DialogContent>

      {!hideActions && (
        <>
          <DialogActions classes={{root: classes.DialogActions}}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            >
              {variant === DialogType.CONFIRMATION && (
                <>
                  <Grid item>
                    <CustomButton className={classes.actionButtonSecondary} onClick={onClose}>
                      <LocalizedText>cancel</LocalizedText>
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <CustomButton
                      className={`${classes.actionButton} ${classes.actionButtonDisabledOverride}`}
                      disabled={preventSubmit}
                      onClick={onSubmit}
                      startIcon={
                        <CustomIcon icon={faCheck} />
                      }
                    >
                      <LocalizedText>validate</LocalizedText>
                    </CustomButton>
                  </Grid>
                </>
              )}
              {variant === DialogType.INFO && (
                <Grid item>
                  <CustomButton className={classes.actionButton} onClick={onClose}>
                    <LocalizedText>ok</LocalizedText>
                  </CustomButton>
                </Grid>
              )}
              {variant === DialogType.ERROR && (
                <Grid item>
                  <CustomButton className={classes.actionButton} onClick={onClose} >
                    <LocalizedText>ok</LocalizedText>
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
};

DialogWrapper.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  preventSubmit: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string
};
DialogWrapper.defaultProps = {
  onClose: () => { },
  onSubmit: () => { },
  open: false,
  preventSubmit: false,
  title: '',
  variant: DialogType.INFO
};