import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import LocalizedText from 'commons/LocalizedText/LocalizedText';
import { useCustomerInfo } from 'hook/CustomerInfoProvider';
import React from 'react';
import { MOBILE_BREAKPOINT } from 'utils/constants/responsive';
import { isAdmin } from 'utils/constants/roles';
import { AutoCompleteWithValue } from "commons/AutoComplete/AutoCompleteWithValue";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: theme.spacing(1)
    }
  },
  divider: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1)
    }
  },
  dropdown: {
    width: '90%',
    maxWidth: '90%',
    minWidth: '90%',
    margin: 0
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: '30px',
    marginBottom: '10px',
    '&::before': {
      content: '"-"',
      backgroundColor: theme.palette.secondary.main,
      marginRight: '10px'
    }
  },
  columnContainer: {
    width: '360px'
  },
  root: () => ({
    flexGrow: 1,
    width: '60%',
    '& .MuiTextField-root': {
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: theme.spacing(1),
        marginBottom: 0
      }
    }
  })
}));

export const ContractsSection = ({
  availableContracts,
  contractList,
  isLoadingContracts,
  seeAllContracts,
  setContractList,
  setSeeAllContracts
}) => {
  const classes = useStyles();
  const { role } = useCustomerInfo();

  const yesNoOptions = ['yes', 'no'];

  const seeAllContractField = (
    <AutoCompleteWithValue
      autoCompleteXS={9}
      className={classes.dropdown}
      isTranslatingOptions
      label="seeAllContracts"
      labelXS={3}
      onChange={(event) => setSeeAllContracts(event === 'yes' ?? false)}
      options={yesNoOptions}
      required
      value={seeAllContracts ? 'yes' : 'no'}
      valueName="seeAllContracts"
      variant='outlined'
    />
  );

  const contractListField = (
    <AutoCompleteWithValue
      className={classes.dropdown}
      disabled={seeAllContracts || isLoadingContracts}
      isLoading={isLoadingContracts}
      isMultiple
      isTranslatingOptions
      label="contractList"
      limitTags={5}
      noOptionsText="noOptionText"
      onChange={(event) => setContractList(event)}
      options={availableContracts}
      value={contractList}
      variant='outlined'
    />
  );

  return (
    <>
      {
        isAdmin(role)
        && (
          <Grid container direction="column">
            <Grid item>
              <LocalizedText className={classes.title}>
                contracts
              </LocalizedText>
            </Grid>
            <Grid className={classes.columnContainer} item>
              {seeAllContractField}
            </Grid>
            {!seeAllContracts &&
              <Grid className={classes.columnContainer} item>
                {contractListField}
              </Grid>
            }
          </Grid>
        )
      }
    </>
  );
};